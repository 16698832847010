import {Autocomplete, AutocompleteItem, Button, TimeInput} from "@nextui-org/react";
import SignatureCanvas from 'react-signature-canvas'
import { useState, useEffect, useRef } from 'react';
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";



function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width: width,
      height: height
    };
  }
const employee_list = [
    "Jed Bartlet",
    "Leo McGarry",
    "Josh Lyman",
    "Toby Ziegler",
    "Sam Seaborn",
    "CJ Cregg",
    "Charlie Young",
    "Donna Moss",
    "Will Bailey",
    "Kate Harper",
    "Annabeth Schott",
    "Amy Gardner",
    "Helen Santos",
    "Matthew Santos",
    "Arnold Vinick",
    "Bob Russell",
    "John Hoynes",
    "Mandy Hampton",
    "Ainsley Hayes",
    "Joe Quincy",
].sort();

const employees = [];

employee_list.forEach((employee, index) => {
    employees.push({
        label: employee,
        value: employee
    }) 
})

function SignInSheet() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [signedInWorkers, setSignedInWorkers] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [mode, setMode] = useState('view');


    const sigRef = useRef();

    const clear = () => {
        sigRef.current.clear();
    }

    const cancel = () => {
        setMode('view');
        clear()
        setEndTime(null);
        setStartTime(null);
        setSelectedEmployee(null);
    }

    const save = () => {
        const pic = sigRef.current.toDataURL();
        let workers = [...signedInWorkers];
        workers.push({
            name: selectedEmployee,
            startTime: startTime,
            endTime: endTime,
            signature: pic
        })
        setSignedInWorkers(workers);
        setMode('view');
        clear()
        setEndTime(null);
        setStartTime(null);
        setSelectedEmployee(null);
    }


    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

  return (
    <div className="p-2">
        {
            mode == 'view' ? 
            <div>
            <h1 className="text-xl	text-center">Mulligan Security</h1>
            <h2 className="text-center">Employee Sign In Sheet</h2>
            <h2 className="text-center">Date: 11/13/2024</h2>
            <div className="flex justify-center" >
            <Button className="my-4" color="primary" onClick={() => setMode("signin")}>Sign In</Button>
            </div>
            
            </div>
            : null
        }
        {
            mode == 'signin' ? 
            <div>
                <Autocomplete 
            label="Employee" 
            className="max-w-xs mb-4" 
            value={selectedEmployee}
            onSelectionChange={(value) => setSelectedEmployee(value)}
        >
            {employees.map((animal) => (
            <AutocompleteItem key={animal.value} value={animal.value}>
                {animal.label}
            </AutocompleteItem>
            ))}
            </Autocomplete>
            <TimeInput 
                className="mb-4"
                isRequired 
                label="Start Time" 
                hourCycle={24}
                onChange={(e) => setStartTime(e)}
            />
            <TimeInput 
            className="mb-4"
            isRequired 
            label="End Time" 
            hourCycle={24}
            onChange={(e) => setEndTime(e)}
            />
                
            <div className="mb-4" style={{border: '1px solid black', width: (windowDimensions.width - 18) + 1}}>
            <SignatureCanvas penColor='black' //backgroundColor="#ececec"
                ref={sigRef}
                canvasProps={{width: windowDimensions.width - 18, height: 300, className: 'sigCanvas'}} />
            </div>
            <div className="flex justify-between">
                <Button onClick={cancel}>Cancel</Button>
                <Button color="primary" onClick={save}>Sign In</Button>
            </div>
            </div>
            : null
        }

        <Table className="flex" fullWidth={true} style={{display: mode == "view" ? 'block': 'none'}} removeWrapper  aria-label="Example static collection table">
            <TableHeader>
                <TableColumn width={"50%"}>Name</TableColumn>
                <TableColumn >Start</TableColumn>
                <TableColumn >End</TableColumn>
                <TableColumn width={"50%"}>Signiture</TableColumn>
            </TableHeader>
            <TableBody>
            {
                signedInWorkers.map((worker, index) => {
                    return (
                        <TableRow key="1">
                            <TableCell>{worker.name}</TableCell>
                            <TableCell>{worker.startTime ? worker.startTime.toString(): ""}</TableCell>
                            <TableCell>{worker.endTime ? worker.endTime.toString(): ""}</TableCell>
                            <TableCell><img src={worker.signature} style={{maxHeight: 60}} alt="signature" /></TableCell>
                        </TableRow>
                    )
                })
            }
                
            </TableBody>
            </Table>
        
    </div>
  );
}

export default SignInSheet;
